import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";

export function ShuffleSharding() {
    return (
        <SpaceBetween size={"m"}>
            <Container header={<Header variant={"h1"}>Test h1</Header>}>
                Yyoyo
            </Container>
        </SpaceBetween>
    );
}
