import './App.css';
import "@awsui/global-styles/index.css"
import { AppLayout, SideNavigation } from '@awsui/components-react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './content/home';
import { ShuffleSharding } from './content/shuffle-sharding/shuffle-sharding';

function retrieveRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/aws-articles/shuffle-sharding' element={<ShuffleSharding />} />
    </Routes>
  );
}

function App() {

  return (
    <AppLayout
      navigation={
        <SideNavigation
          header={{
            href: "/",
            text: "Michael Trottier"
          }}
          items={[
            {
              text: "AWS Articles",
              type: "expandable-link-group",
              href: "#",
              items: [
                {
                  text: "Shuffle Sharding",
                  type: "link",
                  href: "#/aws-articles/shuffle-sharding",
                }
              ]
            }
          ]}
        />
      }
      content={retrieveRoutes()}
      tools={
        < SideNavigation
          header={{
            text: "Michael Trottier Social",
            href: "/"
          }
          }
          items={
            [
              {
                text: "LinkedIn",
                type: "link",
                href: "https://www.linkedin.com/in/michael-trottier-a4946877",
                external: true
              }
            ]}
        />
      }
    />
  );
}

export default App;
