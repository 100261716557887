import { Box, Button, MixedLineBarChart } from "@awsui/components-react";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";

export function Home() {
    const data = [
        { x: "1", y: 100 },
        { x: "2", y: 110 },
        { x: "3", y: 95 },
        { x: "4", y: 88 },
        { x: "5", y: 75 },
        { x: "6", y: 45 },
        { x: "7", y: 32 },
        { x: "8", y: 10 },
        { x: "9", y: 0 },
        { x: "10", y: -10 }
    ];

    return (
        <SpaceBetween size={"m"}>
            <Container header={<Header variant={"h1"}>Home</Header>}>
                Welcome to my website.  Home of various articles learned over my engineering career.
            </Container>
            <Container header={<Header variant={"h2"}>Software effectivness per beer.</Header>}>
                <MixedLineBarChart
                    series={[
                        {
                            title: "Effectiveness per Beer",
                            type: "bar",
                            data: data,
                            valueFormatter: e =>
                                "%" + e.toLocaleString("en-US")
                        },
                        {
                            title: "Effectivness per Beer Trend",
                            type: "line",
                            data: data,
                            valueFormatter: e =>
                                "%" + e.toLocaleString("en-US")
                        },
                        {
                            title: "Max Effectiveness",
                            type: "threshold",
                            y: 110,
                            valueFormatter: e =>
                                "%" + e.toLocaleString("en-US")
                        },
                        {
                            title: "Peak Effectivness",
                            type: "threshold",
                            x: "2"
                        }
                    ]}
                    xDomain={[
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10"
                    ]}
                    yDomain={[-10, 110]}
                    i18nStrings={{
                        filterLabel: "Filter displayed data",
                        filterPlaceholder: "Filter data",
                        filterSelectedAriaLabel: "selected",
                        legendAriaLabel: "Legend",
                        chartAriaRoleDescription: "line chart"
                    }}
                    ariaLabel="Mixed bar chart"
                    errorText="Error loading data."
                    height={300}
                    loadingText="Loading chart"
                    recoveryText="Retry"
                    xScaleType="categorical"
                    xTitle="Beers (Unit.ONE)"
                    yTitle="Effectivness Percentage (%)"
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No data available</b>
                            <Box variant="p" color="inherit">
                                There is no data available
                            </Box>
                        </Box>
                    }
                    noMatch={
                        <Box textAlign="center" color="inherit">
                            <b>No matching data</b>
                            <Box variant="p" color="inherit">
                                There is no matching data to display
                            </Box>
                            <Button>Clear filter</Button>
                        </Box>
                    }
                />
            </Container>
        </SpaceBetween>
    );
}
